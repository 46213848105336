<template>
  <div>
    <div v-loading="loading" :element-loading-text="loadingText">
      <el-card class="main-chart-container">
        <div class="entity-navbar">
          <div class="left">
            <p class="entity-title">{{ getFieldLabel }}</p>
          </div>
          <div class="right d-flex">
            <div v-if="quickAddEntity">
              <quickEntityAddWidget
                class="mt-1 mr-1"
                :currentEntity="quickentityData"
                :parentForm="form"
                :parentFields="fieldsData"
              ></quickEntityAddWidget>
            </div>
            <el-select
              v-model="showFields.board_field"
              placeholder="Select board field"
              @change="setCurrentField()"
              style="width: 100%"
            >
              <el-option
                v-for="(field, i) in getEntityFieldsByType([
                  'SELECT',
                  'ENTITY',
                ])"
                :key="field.key + 'chart' + i"
                :label="field.label"
                :value="field.key"
                >{{ field.label }}</el-option
              >
            </el-select>
          </div>
        </div>
        <div class="chart-main-container">
          <el-scrollbar>
            <div
              class="pie-body"
              v-if="getSelectedFieldsOptions && getSelectedFieldsOptions.length"
            >
              <div
                v-for="(col, i) in getSelectedFieldsOptions"
                :key="i + 'column' + col"
                class="board-column"
              >
                <div class="board-column-head">
                  <div>
                    <el-badge :value="col.length" class="item">
                      <el-tag :type="tagType[Math.floor(i % tagType.length)]">
                        {{ getSelectedFieldLabels[i] | truncate(30, "...") }}
                      </el-tag>
                    </el-badge>
                  </div>
                </div>
                <div v-if="currentEntity.templates && showFields.take_value_from" class="board-column-head mt-2">
                  <el-badge
                    :value="getFormattedTotalAmount(col)"
                    class="item"
                    type="primary"
                    v-if="entityFields"
                  >
                    <el-tag
                      :type="tagType[Math.floor(i % tagType.length)]"
                      size="mini"
                      >{{ getHeadlabel() | truncate(35, "...") }}</el-tag
                    >
                  </el-badge>
                </div>
                <draggable
                  :list="col"
                  style="
                    height: 55vh;
                    overflow-y: auto;
                    scrollbar-width: thin;
                    padding: 0 10px 0 0;
                    overflow-x: hidden;
                  "
                  group="people"
                  :disabled="!checkPerimission('EDIT')"
                >
                  <div
                    v-for="data in col"
                    :key="data._id"
                    class="card mb-3"
                    @drop="checkEntityDataSwipe(data, i)"
                  >
                    <div class="card-body">
                      <el-dropdown
                        v-if="showFields.enableOtherOptions"
                        :class="dropdownPositionClass"
                        style="position: absolute; top: 10px; right: 10px"
                      >
                        <span class="el-dropdown-link">
                          <i
                            class="el-icon-more"
                            style="transform: rotate(90deg); font-size: 14px"
                          ></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <a
                            :underline="false"
                            @click="duplicateEntityData(data)"
                            v-if="
                              checkPerimission('DUPLICATE') &&
                              currentEntity &&
                              currentEntity.entity_type != 'INDIVIDUAL'
                            "
                          >
                            <el-dropdown-item icon="el-icon-document-copy"
                              >Duplicate</el-dropdown-item
                            >
                          </a>
                          <a
                            @click="deleteEntityData(data)"
                            :underline="false"
                            v-if="checkPerimission('DELETE')"
                          >
                            <el-dropdown-item icon="el-icon-delete"
                              >Delete</el-dropdown-item
                            >
                          </a>
                        </el-dropdown-menu>
                      </el-dropdown>
                      <h5
                        v-if="entityFields"
                        @click="viewWorkUserData(data)"
                        style="margin-right: 8px"
                      >
                        {{ getLabel(data) | truncate(20, "...") }}
                      </h5>
                      <div class="card-text">
                        <div
                          v-for="row in showFields.BoardViewFields"
                          :key="row"
                          class="mb-2"
                        >
                          <!-- <el-tooltip
                            class="item"
                            effect="dark"
                            :content="getFieldLabels(row)"
                            placement="top"
                          > -->
                          <span class="bold-key"
                          >{{ getFieldLabels(row) }} </span
                        >:
                            <span>{{
                              getFieldValue(row, data) | truncate(22, "...")
                            }}</span>
                          <!-- </el-tooltip> -->
                        </div>
                      </div>
                      <template
                        v-if="
                          showFields.Board_action_group_button &&
                          showFields.Board_action_group_button.length
                        "
                      >
                        <div class="mt-3">
                          <div
                            v-for="(
                              action, index
                            ) in showFields.Board_action_group_button"
                            :key="index"
                          >
                            <a
                              href="javascript:void(0);"
                              class="card-link text-primary"
                              @click="
                                onActionButtonOpen(
                                  {
                                    template_id: action.split('#')[0],
                                    id: action.split('#')[1],
                                  },
                                  data
                                )
                              "
                            >
                              {{ getActionField(action) }}
                            </a>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                  <div class="add-card-button">
                    <el-button class="circle-button" @click="addEntityData(i)">
                      +
                    </el-button>
                  </div>
                </draggable>
              </div>
            </div>
            <div class="entity-empty" v-else>
              <el-empty :description="getDescription"></el-empty>
            </div>
          </el-scrollbar>
        </div>
      </el-card>
    </div>

    <div v-if="quickActionModal && quickActionDummyField">
      <ActionButtonExecute
        :data="quickActionDummyField"
        :isView="false"
        :entityId="currentEntity._id"
        :entityDataId="quickActionDummyField.parent_entityDataId"
        :fromQuickAction="true"
        @closeModal="quickActionModal = false"
      >
      </ActionButtonExecute>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NavigationHelper from "@/mixins/navigationHelper";
import draggable from "vuedraggable";
import EntitiesHelper from "@/mixins/EntitiesHelper";
import FieldsFormatHelper from "@/mixins/FieldsFormatHelper";
import CustomDashboardConfig from "@/mixins/CustomDashboardHelper.js";
import { fetchEntityById } from "@/repo/entityRepo";
import axios from "@/config/axios";

export default {
  data() {
    return {
      copyData: [],
      localShowFields: { ...this.showFields },
      fieldId: "",
      showWidget: false,
      selectedField : null,
      entityOptionsObj: null,
      quickentityData: {},
      number_fields: [],
      required_sum: 0,
      tagType: ["success", "info", "", "warning", "danger"],
      chartData: {
        labels: [],
        datasets: [
          {
            backgroundColor: [],
            data: [],
            label: "",
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
      componentData: {},

      baseColor: "#65cff0",
      loading: false,
      entity_id: "",
      currentEntity: {},
      loadingText: "Gathering data...",
      refresh: false,
      chartTypes: [
        {
          label: "Pie",
          value: "PIE",
        },
        {
          label: "Bar",
          value: "BAR",
        },
        {
          label: "Doughnut",
          value: "DOUGHNUT",
        },
      ],
      allEntityDataList: [],
      getSelectedFieldsOptions: [],
      getSelectedFieldLabels: [],
      getStandardSelectedFieldOptions: [],
      customDashboardBoardStats: null,
      entityFields: [],
      selectedEntityFields: [],
      quickActionModal: false,
      quickActionDummyField: null,
    };
  },
  computed: {
    ...mapGetters("templatesData", [
      "getTemplateDeleteStatus",
      "getTemplateDeleteError",
      "getDownloadUrl",
      "getDownloadError",
      "getDuplicateEntityData",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("navigationOpen", [
      "getIsOpen",
      "getIsCollapse",
      "getIsMobile",
    ]),
    ...mapGetters("entities", [
      "getEntityDataById",
      "getEntityDataByEntityId",
      "getEntityDataUpdateStatus",
      "getEntityDocumentsDataStatus",
      "getEntityDocumentsData",
      "getUploadCSVfileStatus",
      "getupdateMappedFieldStatus",
      "getmappedFieldsData",
      "getuploadedFilesData",
      "getstoreUploadedFileStatus",
      "getimportMappedDataStatusUpdatedVersion",
      "getentitydataBulkDeleteStatus",
      "getEntitydataBulkDeleteErrors",
      "geCustomDashboardPieStats",
    ]),
    ...mapGetters("applicationUsersPermission", [
      "getCreatePermissionStatus",
      "getEntityPermission",
      "getUpdatePermissionStatus",
    ]),
    dropdownPositionClass() {
      return "dropdown-bottom";
    },
    getFieldLabel() {
      if (this.showFields?.board_field) {
        if (
          this.showFields?.board_field &&
          this.showFields.board_field.includes("#")
        ) {
          let field = this.getEntityFieldsByType(["SELECT", "ENTITY"]).find(
            (e) => e.key == this.showFields.board_field
          );
          if (field?.label) {
            return field.label;
          }
          return this.currentEntity?.name;
        }
        return this.currentEntity?.name;
      }
      return this.currentEntity?.name;
    },
    getDescription() {
      if (!this.showFields.board_field) {
        return "Field not selected...";
      }
      return "No options...";
    },
    quickAddEntity() {
      return (
        this.selectedField?.entity_id &&
        this.selectedField?.allow_quick_add &&
        this.selectedField?.inputType === "ENTITY"
      );
    },
    getCount() {
      return (col) => {
        if (
          this.showFields.board_field &&
          this.showFields.board_field.includes("#") &&
          col &&
          this.allEntityDataList
        ) {
          let count = 0;
          let [temp, key] = this.showFields.board_field.split("#");
          this.allEntityDataList.forEach((data) => {
            if (
              data?.entityData &&
              temp &&
              data.entityData[temp] &&
              data.entityData[temp][key] &&
              data.entityData[temp][key] == col
            ) {
              count++;
            }
          });
          return count;
        }
        return 0;
      };
    },
    getCards() {
      return (col, type) => {
        if (
          this.showFields.board_field &&
          this.showFields.board_field.includes("#") &&
          col &&
          this.allEntityDataList
        ) {
          let result = [];
          let [temp, key] = this.showFields.board_field.split("#");
          if (type === "ENTITY") {
            key += "/name";
          }
          this.allEntityDataList.forEach((data) => {
            if (
              data?.entityData &&
              temp &&
              data.entityData[temp] &&
              data.entityData[temp][key] &&
              data.entityData[temp][key] == col
            ) {
              result.push(data);
            }
          });
          return result;
        }
        return [];
      };
    },
  },
  mixins: [
    NavigationHelper,
    EntitiesHelper,
    FieldsFormatHelper,
    CustomDashboardConfig,
  ],
  components: {
    quickEntityAddWidget: () =>
      import("@/components/widgets/quickEntityAddWidget"),
    draggable,
    ActionButtonExecute: () =>
      import(
        "@/components/templates/formComponentsExecute/ActionButtonExecute"
      ),
  },
  watch: {
    "showFields.board_field": {
      handler: function (newValue, oldValue) {
        if (oldValue !== newValue) {
          this.loadBoardData();
        }
      },
      deep: true,
    },
    showFields: {
      handler: function () {
        if (
          JSON.stringify(this.showFields) != JSON.stringify(this.showFields)
        ) {
          this.localShowFields = { ...this.showFields };
          this.updateShowFields();
        }
      },
      deep: true,
    },
  },
  props: [
    "data",
    "form",
    "entityId",
    "search_string",
    "showFields",
    "isApplicationUserSide",
    "currentMenu",
    "fieldsData",
    "getSelfEntityFilters",
    "applyFiltersData",
    "entityFiltersData",
    "appliedQuickFilters",
    "entityUserFiltersData",
  ],
  mounted() {
    this.entity_id = this.$route.params.entity_id || this.entityId;
    if (this.$route.query.board_field) {
      this.showFields.board_field = this.$route.query.board_field;
    }
    this.getNecessaryInfo();
  },
  methods: {
    async setCurrentField() {
      let field = this.getEntityFieldsByType(["SELECT", "ENTITY"]).find(
        (e) => e.key == this.showFields.board_field
      );
      this.selectedField = field;
      if (field.inputType == "ENTITY" && field.entity_id) {
        this.quickentityData = await fetchEntityById(field.entity_id, false);
      }
    },
    updateShowFields() {
      this.$emit("update:showFields", this.localShowFields); // Emit the change to parent
    },
    getFieldLabels(item) {
      let field = this.entityFields.find((e) => e.key == item.split("#")[1]);
      if (field) {
        return field.orgLabel || "";
      }
      return "";
    },
    async addEntityData(index) {
      let query = this.getNavigationQuery(this.$route.query);
      let viewType = "STANDARD";
      if (this.currentEntity?.entity_type == "INDIVIDUAL") {
        viewType = "PROFILE";
      }
      const boardField = this.showFields.board_field;
      if (this.isApplicationUserSide) {
        this.$router.push({
          path: `/ap/entity-execute/${this.entity_id}`,
          query: {
            ...query,
            ...{
              menu_id: this.$route.params.menuId,
              layout: viewType,
              type: "s7aLF3NnAQg=",
              index: this.getSelectedFieldLabels[index], // Send index
              board_field: boardField,
            },
          },
        });
      } else {
        let UrlPathAdd = "";
        if (this.getIframeUrlInfo != null) {
          UrlPathAdd = `/if/${this.getIframeUrlInfo._id}/entity/edit/${this.entity_id}`;
        } else {
          UrlPathAdd = `/entity/edit/${this.entity_id}`;
        }
        this.$router.push({
          path: UrlPathAdd,
          query: {
            ...query,
            ...{
              layout: viewType,
              index: this.getSelectedFieldLabels[index],
              board_field: boardField,
            },
          },
        });
      }
    },
    checkEntityDataSwipe(data, col) {
      setTimeout(() => {
        this.getSelectedFieldsOptions.forEach((column, i) => {
          if (i !== col) {
            (column || []).forEach((card) => {
              if (
                card &&
                data &&
                card._id === data._id &&
                this.showFields?.board_field &&
                this.showFields.board_field.includes("#")
              ) {
                this.updateEntityData(this.getSelectedFieldLabels[i], data);
              }
            });
          }
        });
      }, 300);
    },
    checkPerimission(access) {
      if (this.isApplicationUserSide) {
        if (
          this.getEntityPermission &&
          this.currentMenu &&
          (this.currentMenu.entity_id || this.currentMenu.relational_entity)
        ) {
          if (access == "UPDATE") {
            access = "EDIT";
          }
          if (this.currentMenu.entity_id) {
            let entityId = this.currentMenu.entity_id._id
              ? this.currentMenu.entity_id._id
              : this.currentMenu.entity_id;
            if (
              this.currentMenu.entity_type == "ENTITY" &&
              this.getEntityPermission.other_entities &&
              entityId &&
              this.getEntityPermission.other_entities[entityId] &&
              this.getEntityPermission.other_entities[entityId].permissions &&
              this.getEntityPermission.other_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          } else if (this.currentMenu.relational_entity) {
            let entityId = this.currentMenu.relational_entity._id
              ? this.currentMenu.relational_entity._id
              : this.currentMenu.relational_entity;
            if (
              entityId &&
              this.getEntityPermission.relational_entities[entityId] &&
              this.getEntityPermission.relational_entities[entityId]
                .permissions &&
              this.getEntityPermission.relational_entities[
                entityId
              ].permissions.indexOf(access) != -1
            ) {
              return true;
            }
          }
          return false;
        }
        return true;
      } else {
        if (
          this.entityFiltersData?.permissions?.access_set &&
          this.entityFiltersData?._id
        ) {
          if (access == "EDIT") {
            access = "UPDATE";
          }
          if (access == "IMPORT" || access == "EXPORT") {
            return true;
          }
          return (
            this.entityFiltersData.permissions.access_set.indexOf(access) != -1
          );
        }
        return true;
      }
    },
    async updateEntityData(selectedData, entityData) {
      let [temp, key] = this.showFields.board_field.split("#");
      if (entityData?.entityData && temp && key) {
        let form = entityData.entityData[temp];
        if(this.selectedField.inputType == "ENTITY") {
          form[key] = this.entityOptionsObj[selectedData];
          form[`${key}/name`] = selectedData;
        } else {
          form[key] = selectedData;
        }
        let params = {
          template_data: form,
          entity_data_id: entityData._id,
          template_id: temp,
          entity_id: entityData.entity_id,
        };
        await this.$store.dispatch(
          "templatesData/updateUserTemplateData",
          params
        );
      }
    },
    getLabel(item, i) {
      let selectedEntity = this.currentEntity ? this.currentEntity : {};
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    getFieldValue(item, col) {
      let field = this.entityFields.find((e) => e.key == item.split("#")[1]);
      let form = col.entityData[item.split("#")[0]];
      if (field) {
        let data = this.getFormattedFieldData(field, form);
        return data;
      }
      return null;
    },
    getHeadlabel() {
      if (
        this.showFields.take_value_from != "" ||
        this.showFields.take_value_from != undefined
      ) {
        let field = this.entityFields.find(
          (e) => e.key == this.showFields.take_value_from?.split("#")[1]
        );
        if (field) {
          const labelParts = field.label.split("-");
            if (labelParts.length > 1) {
                console.log("Field Value:", labelParts[1].trim()); 
                return labelParts[1].trim() + " Total";
            }
            else{
          return field.label;
            }
        }
        return "";
      }
      return "";
    },
    getFormattedTotalAmount(data) {
      if (
        this.showFields.take_value_from != "" ||
        this.showFields.take_value_from != undefined
      ) {
        const result = this.showFields.take_value_from?.split("#")[1];
        let totalAmount = 0;
        let templates_list = [];

        if (this.currentEntity?.templates) {
            templates_list = this.currentEntity.templates.map(
                (e) => e.template_id
            );
        }
        let currencyCode = '';

        data.forEach((item) => {
            if (item.entityData) {
                templates_list.forEach((templateId) => {
                    const required_template_data = item.entityData[templateId];
                    if (required_template_data) {
                        for (let key in required_template_data) {
                            if (key.toLowerCase().includes("currency")) {
                                currencyCode = required_template_data[key];
                                break;
                            }
                        }
                        if (required_template_data[result]) {
                            totalAmount += Number(required_template_data[result]);
                        }
                    }
                });
            }
        });

        if (currencyCode) {
          const currencyLocale = this.getLocale(currencyCode);
          try {
            const formattedTotalAmount = new Intl.NumberFormat(currencyLocale, {
              style: "currency",
              currency: currencyCode,
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            }).format(totalAmount);
            return formattedTotalAmount;
          } catch (error) {
            return `Invalid currency format for code: ${currencyCode}`;
          }
        } else {
          console.warn("Currency code not found, defaulting to USD.");
          return totalAmount;
        }
      }
      return "";
    },
    getLocale(currency) {
      switch (currency) {
        case "INR":
          return "en-IN";
        case "EUR":
          return "en-IE";
        case "CAD":
        case "AUD":
        case "USD":
          return "en-US";
      }
    },
    getActionField(item) {
      let field = this.entityFields.find((e) => e.key == item.split("#")[1]);
      return field.orgLabel;
    },
    resetChartData() {
      this.chartData.labels = [];
      this.chartData.datasets = [
        {
          backgroundColor: [],
          data: [],
          label: "",
        },
      ];
    },
    async viewWorkUserData(row) {
      if (row._id) {
        if (this.isApplicationUserSide) {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/ap/entity-view/${this.entity_id}`,
            query: {
              ...query,
              dataId: row._id,
              filter: this.currentFilter,
              menu_id: this.$route.params.menuId,
            },
          });
        } else {
          this.entityDataId = row._id;
          let query = this.getNavigationQuery(this.$route.query);
          this.$router.push({
            path: `/entity/view/${this.entity_id}`,
            query: { ...query, dataId: row._id, filter: this.currentFilter },
          });
        }
      }
    },
    async updateRouterParams() {
      let query = this.getNavigationQuery(this.$route.query);
      query["board_field"] = this.showFields.board_field;
      this.$router.push({
        name: this.$route.name,
        query: query,
      });
    },
    async loadBoardData(filters = []) {
      if (this.showFields.board_field) {
        this.loading = true;
        if (
          this.currentMenu?.entity_type == "RELATIONAL_ENTITY" &&
          this.isApplicationUserSide
        ) {
          filters = [...filters, ...this.getSelfEntityFilters];
        }
        filters = [...filters,...(this.entityFiltersData.filters || []),...(this.entityUserFiltersData.filters||[]),...(this.appliedQuickFilters || [])];
        this.loadingText = "Gathering " + this.currentEntity?.name + " data";
        let params = {
          entity_id: this.entity_id,
          template_fields_data: (this.currentEntity.templates || []).map(
            (template) => {
              return {
                template_id: template.template_id,
              };
            }
          ),
          filters: [...this.applyFiltersData.filters,
          ...filters,
        ],
          include_contact_profile: true,
        };

        // await this.$store.dispatch(
        //   "entities/fetchEntityRecordsForTable",
        //   params
        // );
        const response = await axios.post("/entities-data/entity/data", params);
        this.customDashboardBoardStats = { ...response.data };
        if (
          this.customDashboardBoardStats &&
          this.customDashboardBoardStats.data
        ) {
          this.allEntityDataList = this.customDashboardBoardStats.data;
          await this.filterEntityData(this.allEntityDataList);
        }
        this.loading = false;
      }
    },
    filterEntityData() {
      this.getSelectedFieldsOptions = [];
      this.getSelectedFieldLabels = [];
      if (this.showFields?.board_field) {
        if (
          this.showFields?.board_field &&
          this.showFields.board_field.includes("#")
        ) {
          let field = this.getEntityFieldsByType(["SELECT", "ENTITY"]).find(
            (e) => e.key == this.showFields.board_field
          );
          if (
            field?.inputType &&
            field.inputType == "SELECT" &&
            field.options
          ) {
            field.options.forEach((op) => {
              this.getSelectedFieldsOptions.push(this.getCards(op));
              this.getSelectedFieldLabels.push(op);
              this.getStandardSelectedFieldOptions.push(this.getCards(op));
            });
          } else if (field?.inputType === "ENTITY" && field.key) {
            const [templateId, fieldKey] = field.key.split("#");
            const entityNameKey = `${fieldKey}/name`;
            let matchedEntityData = [];
            this.entityOptionsObj = {};
            this.allEntityDataList.forEach((entity) => {
              if (entity?.entityData?.[templateId]) {
                const entityData = entity.entityData[templateId];
                if (entityData[entityNameKey] && entityData[fieldKey]) {
                  matchedEntityData.push(entityData[entityNameKey]);
                  this.entityOptionsObj[entityData[entityNameKey]] =
                    entityData[fieldKey];
                }
              } 
            });
            matchedEntityData = Array.from(new Set(matchedEntityData));
            matchedEntityData.forEach((data) => {
              const card = this.getCards(data, "ENTITY");
              this.getSelectedFieldsOptions.push(card);
              this.getSelectedFieldLabels.push(data);
              this.getStandardSelectedFieldOptions.push(card);
            });
          }
        }
      }
    },
    async getNecessaryInfo() {
      this.loading = true;
      await this.$store.dispatch("entities/fetchEntityById", {
        entity_id: this.entity_id,
      });
      if (this.getEntityDataById) {
        this.currentEntity = this.getEntityDataById;
        this.loadBoardData();
      }
      this.entityFields = await this.fetchEntityDetails(
        this.getEntityDataById._id,
        true
      );
      this.setCurrentField();
      this.selectedEntityFields = [];
      if (this.getEntityDataById) {
        this.currentEntity = this.getEntityDataById;
        if (this.currentEntity?.views_configuration) {
          this.localShowFields = { ...this.currentEntity.views_configuration };
        } else if (this.showFieldsParent) {
          this.localShowFields = { ...this.showFieldsParent };
        }
        if (this.currentEntity && this.currentEntity.templates) {
          this.currentEntity.templates.forEach((e) => {
            if (e && e.templateInfo && !e.is_repeatable) {
              let dt = JSON.parse(JSON.stringify(e.templateInfo));
              this.selectedEntityFields = [
                ...this.selectedEntityFields,
                ...this.getTemplateFields(dt),
              ];
            }
          });
        }
      }
      this.loading = false;
    },
    getTemplateFields(data) {
      let fields = [];
      data.sections.forEach((e) => {
        fields = [...fields, ...e.fields];
      });

      fields = fields.map((e) => {
        e.template_id = data._id;
        if (e.key && !e.key.includes("#")) {
          e.key = data._id + "#" + e.key;
        }
        e.template_name = data.name;
        e.data_type =
          e.validations && e.validations.type ? e.validations.type : "STRING";
        return e;
      });
      fields.forEach((f) => {
        if (
          f.inputType === "NUMBER" ||
          f.inputType === "CURRENCY" ||
          f.inputType === "AGGREGATE_FUNCTION" ||
          (f.inputType === "FORMULA" && f.result_type === "NUMBER")
        ) {
          this.number_fields.push(f);
        }
      });
      return fields;
    },
    getEntityFieldsByType(types) {
      let results = [];
      (this.currentEntity && this.currentEntity.templates
        ? this.currentEntity.templates
        : []
      ).forEach((temp) => {
        if (temp?.templateInfo?.sections && !temp.is_repeatable) {
          if (types && types.length) {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields
                .filter((e) => types.indexOf(e.inputType) != -1)
                .map((el) => {
                  let key = el.key;
                  if (!key.includes("#")) {
                    key = temp.template_id + "#" + key;
                  }
                  el.key = key;
                  return el;
                }),
            ];
          } else {
            results = [
              ...results,
              ...temp.templateInfo.sections[0].fields.map((el) => {
                let key = el.key;
                if (!key.includes("#")) {
                  key = temp.template_id + "#" + key;
                }
                el.key = key;
                return el;
              }),
            ];
          }
        }
      });
      if (
        types.includes("IMAGE") &&
        this.currentEntity?.entity_type == "INDIVIDUAL"
      ) {
        results.push({
          label: "Default picture",
          key: "defaultpic",
        });
      }
      return results;
    },
    deleteEntityData(row) {
      this.$confirm("Are you sure to delete the data?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.checkDeleteEntityData(row);
      });
    },
    async checkDeleteEntityData(entityData) {
      let params = {
        id: entityData._id,
        entity_id: entityData.entity_id,
      };
      let resourceFilters = this.selectedEntityFields
        .filter((e) => {
          if (e?.inputType == "ENTITY" && e.filters) {
            return e.filters.find((f) => f.field == "RESOURCE_MANAGEMENT");
          }
        })
        .map((e) => {
          let newField = {
            key: e.key,
            label: e.label,
            filters: e.filters,
            entity_id: e.entity_id,
            template_id: e.template_id,
          };
          if (e.data_table_key) {
            newField["data_table_key"] = e.data_table_key;
            newField["data_table_field_index"] = e.data_table_field_index;
          }
          return newField;
        });
      if (resourceFilters && resourceFilters.length) {
        params["resource_filters"] = resourceFilters;
        params["entityData"] = entityData;
      }
      await this.$store.dispatch("templatesData/deleteEntityData", params);
      if (this.getTemplateDeleteStatus) {
        // this.fetchAllEmployee();
        this.loadBoardData();
        this.$notify.success({
          title: "Success",
          message: "Entity Data deleted successfully",
        });
      } else {
        this.$notify.error({
          title: "Error",
          message:
            this.getTemplateDeleteError || "Error on deleting Entity data",
        });
      }
    },
    async duplicateEntityData(row) {
      this.loading = true;
      let data = {
        entity_id: this.entity_id,
        existed_data_id: row._id,
        current_date: this.getCurrentDate,
      };
      await this.$store.dispatch("templatesData/duplicateEntityData", data);
      if (this.getDuplicateEntityData?._id) {
        this.$notify.success({
          title: "Success",
          message: "Data duplicated successfully",
        });
        this.loading = false;
        this.loadBoardData();
        // this.fetchEntitiesDataForTable();
      } else {
        this.loading = false;
        this.$notify.error({
          title: "Error",
          message:
            "Error while duplicating " +
            (this.currentEntity && this.currentEntity.name
              ? this.currentEntity.name
              : "data"),
        });
      }
    },
  },

  // checkRolePermission(permission) {
  //   if (this.getUserType?.permissionSet && !this.isApplicationUserSide) {
  //     return this.getUserType.permissionSet.includes(permission);
  //   }
  //   return true;
  // },
};
</script>
<style scoped lang="scss">
.bold-key {
  font-weight: 700;
  color: black;
}
.card-data {
    justify-content: first baseline;
    align-items: center;
    margin-left: 8px;
    width: 50px;
  }
.main-chart-container {
  width: 100% !important;
  .entity-navbar {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
    padding-bottom: 5px;
  }
  .left {
    .entity-title {
      color: #0e1216;
      font-weight: 500;
      font-size: 18px;
      margin-top: 5px;
    }
    order: 1;
  }

  .right {
    order: 2;
  }
  .chart-main-container {
    margin-top: 5px;
    .pie-body {
      display: flex !important;
      gap: 10px;
      max-height: 70vh;
      .board-column {
        width: 25%;
        background-color: white;
        padding: 10px 0px 10px 10px;
        border-radius: 10px;
        cursor:pointer;
        background-color: #ebebeb;
        .board-column-head {
          display: flex !important;
          text-align: center;
          margin-bottom: 5%;
          .count-circle {
            border-radius: 50%;
            width: 25px;
            height: 25px;
            border: 1px solid #e6e6e6;
            margin-left: 3px;
            margin-top: 3px;
            font-size: 10px;
            background-color: var(--primary-color);
            color: var(--primary-contrast-color);
            div {
              margin-top: 5px !important;
            }
          }
        }
        .board-card-body {
          cursor: pointer;
          width: 100%;
          height: auto;
          background: #ffffff;
          border: 0.5px solid #ebeef5;
          box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.04);
          border-radius: 2px;
          margin-top: 15px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2% 0%;
          flex-direction: column;
        }

        .board-card-body:hover {
          transform: translateY(-4px); /* Slight lift effect */
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Increase shadow on hover */
          background-color: lightslategray;
          color: white;
        }
        .board-card-body:active {
          transform: translateY(-4px); /* Slight lift effect */
          box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1); /* Increase shadow on hover */
          background-color: var(--lighter-primary-color);
          color: var(--primary-contrast-color);
        }
      }
    }
  }
}
</style>

<style scoped>
.el-card {
  border-radius: 4px;
  border: 0px solid #ebeef5;
  background-color: transparent;
  overflow: hidden;
  color: #303133;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.el-card.is-always-shadow {
  box-shadow: none;
}

.card {
  border-radius: 10px;
}

.card-body {
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.card-body:hover {
  box-shadow: 0 8px 10px rgba(0, 0, 0, 0.2);
}

.add-card-button {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.circle-button {
  background-color: transparent;
  border: 2px solid #8a8e93; /* Border color matching the theme */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding: 0;
  font-size: 18px;
  color: #8a8e93;
  line-height: 3px;
  text-align: center;
  cursor: pointer;
}

.circle-button:hover {
  background-color: rgba(64, 158, 255, 0.1);
}

.dropdown-bottom {
  bottom: -100%;
}
</style>
