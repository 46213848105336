var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],attrs:{"element-loading-text":_vm.loadingText}},[_c('el-card',{staticClass:"main-chart-container"},[_c('div',{staticClass:"entity-navbar"},[_c('div',{staticClass:"left"},[_c('p',{staticClass:"entity-title"},[_vm._v(_vm._s(_vm.getFieldLabel))])]),_c('div',{staticClass:"right d-flex"},[(_vm.quickAddEntity)?_c('div',[_c('quickEntityAddWidget',{staticClass:"mt-1 mr-1",attrs:{"currentEntity":_vm.quickentityData,"parentForm":_vm.form,"parentFields":_vm.fieldsData}})],1):_vm._e(),_c('el-select',{staticStyle:{"width":"100%"},attrs:{"placeholder":"Select board field"},on:{"change":function($event){return _vm.setCurrentField()}},model:{value:(_vm.showFields.board_field),callback:function ($$v) {_vm.$set(_vm.showFields, "board_field", $$v)},expression:"showFields.board_field"}},_vm._l((_vm.getEntityFieldsByType([
                'SELECT',
                'ENTITY',
              ])),function(field,i){return _c('el-option',{key:field.key + 'chart' + i,attrs:{"label":field.label,"value":field.key}},[_vm._v(_vm._s(field.label))])}),1)],1)]),_c('div',{staticClass:"chart-main-container"},[_c('el-scrollbar',[(_vm.getSelectedFieldsOptions && _vm.getSelectedFieldsOptions.length)?_c('div',{staticClass:"pie-body"},_vm._l((_vm.getSelectedFieldsOptions),function(col,i){return _c('div',{key:i + 'column' + col,staticClass:"board-column"},[_c('div',{staticClass:"board-column-head"},[_c('div',[_c('el-badge',{staticClass:"item",attrs:{"value":col.length}},[_c('el-tag',{attrs:{"type":_vm.tagType[Math.floor(i % _vm.tagType.length)]}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getSelectedFieldLabels[i],30, "..."))+" ")])],1)],1)]),(_vm.currentEntity.templates && _vm.showFields.take_value_from)?_c('div',{staticClass:"board-column-head mt-2"},[(_vm.entityFields)?_c('el-badge',{staticClass:"item",attrs:{"value":_vm.getFormattedTotalAmount(col),"type":"primary"}},[_c('el-tag',{attrs:{"type":_vm.tagType[Math.floor(i % _vm.tagType.length)],"size":"mini"}},[_vm._v(_vm._s(_vm._f("truncate")(_vm.getHeadlabel(),35, "...")))])],1):_vm._e()],1):_vm._e(),_c('draggable',{staticStyle:{"height":"55vh","overflow-y":"auto","scrollbar-width":"thin","padding":"0 10px 0 0","overflow-x":"hidden"},attrs:{"list":col,"group":"people","disabled":!_vm.checkPerimission('EDIT')}},[_vm._l((col),function(data){return _c('div',{key:data._id,staticClass:"card mb-3",on:{"drop":function($event){return _vm.checkEntityDataSwipe(data, i)}}},[_c('div',{staticClass:"card-body"},[(_vm.showFields.enableOtherOptions)?_c('el-dropdown',{class:_vm.dropdownPositionClass,staticStyle:{"position":"absolute","top":"10px","right":"10px"}},[_c('span',{staticClass:"el-dropdown-link"},[_c('i',{staticClass:"el-icon-more",staticStyle:{"transform":"rotate(90deg)","font-size":"14px"}})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(
                            _vm.checkPerimission('DUPLICATE') &&
                            _vm.currentEntity &&
                            _vm.currentEntity.entity_type != 'INDIVIDUAL'
                          )?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.duplicateEntityData(data)}}},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-document-copy"}},[_vm._v("Duplicate")])],1):_vm._e(),(_vm.checkPerimission('DELETE'))?_c('a',{attrs:{"underline":false},on:{"click":function($event){return _vm.deleteEntityData(data)}}},[_c('el-dropdown-item',{attrs:{"icon":"el-icon-delete"}},[_vm._v("Delete")])],1):_vm._e()])],1):_vm._e(),(_vm.entityFields)?_c('h5',{staticStyle:{"margin-right":"8px"},on:{"click":function($event){return _vm.viewWorkUserData(data)}}},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.getLabel(data),20, "..."))+" ")]):_vm._e(),_c('div',{staticClass:"card-text"},_vm._l((_vm.showFields.BoardViewFields),function(row){return _c('div',{key:row,staticClass:"mb-2"},[_c('span',{staticClass:"bold-key"},[_vm._v(_vm._s(_vm.getFieldLabels(row))+" ")]),_vm._v(": "),_c('span',[_vm._v(_vm._s(_vm._f("truncate")(_vm.getFieldValue(row, data),22, "...")))])])}),0),(
                        _vm.showFields.Board_action_group_button &&
                        _vm.showFields.Board_action_group_button.length
                      )?[_c('div',{staticClass:"mt-3"},_vm._l((_vm.showFields.Board_action_group_button),function(action,index){return _c('div',{key:index},[_c('a',{staticClass:"card-link text-primary",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){_vm.onActionButtonOpen(
                                {
                                  template_id: action.split('#')[0],
                                  id: action.split('#')[1],
                                },
                                data
                              )}}},[_vm._v(" "+_vm._s(_vm.getActionField(action))+" ")])])}),0)]:_vm._e()],2)])}),_c('div',{staticClass:"add-card-button"},[_c('el-button',{staticClass:"circle-button",on:{"click":function($event){return _vm.addEntityData(i)}}},[_vm._v(" + ")])],1)],2)],1)}),0):_c('div',{staticClass:"entity-empty"},[_c('el-empty',{attrs:{"description":_vm.getDescription}})],1)])],1)])],1),(_vm.quickActionModal && _vm.quickActionDummyField)?_c('div',[_c('ActionButtonExecute',{attrs:{"data":_vm.quickActionDummyField,"isView":false,"entityId":_vm.currentEntity._id,"entityDataId":_vm.quickActionDummyField.parent_entityDataId,"fromQuickAction":true},on:{"closeModal":function($event){_vm.quickActionModal = false}}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }